import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Menu, X } from "react-feather"
import Scrollspy from "react-scrollspy"
import CocatusLogo from "../../../images/product/cocatus-logo.png"
import CocatusText from "../../../images/product/cocatus-text.png"
import { Container } from "../../global"
import {
  ActionsContainer,
  Brand,
  Mobile,
  MobileMenu,
  Nav,
  NavItem,
  NavListWrapper,
  StyledContainer,
} from "./style"

const NAV_ITEMS = ["Features", "Product", "FAQ", ""]

const styles = {
  textLogo: {
    height: 64,
  },
  mobileLogo: {
    height: 28,
  },
}

export default class Navigation extends Component {
  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = (item) => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
      {item}
    </AnchorLink>
  )

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      {mobile && (
        <button
          style={{
            marginTop: "0.75em",
            fontSize: 22,
            fontFamily: "'HK Grotesk Medium'",
          }}
          onClick={() => {
            window.location.assign("https://beta.cocatus.com/")
          }}
        >
          Login/Sign up
        </button>
      )}
      <Scrollspy
        style={mobile ? { marginTop: 0 } : undefined}
        items={NAV_ITEMS.map((item) => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map((navItem) => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav
        {...this.props}
        scrolled={this.state.hasScrolled}
        mobileMenuOpen={this.state.mobileMenuOpen}
      >
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <AnchorLink href="#top" onClick={this.closeMobileMenu}>
                <Mobile>
                  <img
                    style={styles.mobileLogo}
                    src={CocatusText}
                    alt="Cocatus"
                  />
                </Mobile>
                <Mobile hide>
                  <img
                    style={styles.textLogo}
                    src={CocatusLogo}
                    alt="Cocatus"
                  />
                </Mobile>
              </AnchorLink>
            </Scrollspy>
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "white", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
          <ActionsContainer>
            <button
              onClick={() => {
                window.location.assign("https://beta.cocatus.com/")
              }}
            >
              Login/Sign up
            </button>
          </ActionsContainer>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    )
  }
}
