import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <StyledSection id="features">
    <StyledContainer>
      <SectionTitle>How it Works</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Step 1:</FeatureTitle>
          <FeatureText>
            The Cocatus platform analyzes the clinical text you provide to
            generate the most accurate codes
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Step 2:</FeatureTitle>
          <FeatureText>
            Critical code-related information is presented to you for review
            within context
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Step 3:</FeatureTitle>
          <FeatureText>
            You are empowered to refine codes using our intuitive tabular search
            and index search if needed
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
    <div className="custom-shape-divider-top-1643511839">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 110"
        preserveAspectRatio="none"
      >
        <path
          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
          className="shape-fill"
        ></path>
      </svg>
    </div>
    <div id="product" className="custom-shape-divider-bottom-1643512573">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 118"
        preserveAspectRatio="none"
      >
        <path
          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
          className="shape-fill"
        ></path>
      </svg>
    </div>
  </StyledSection>
)

export default Features

const StyledSection = styled(Section)`
  position: relative;
  padding: 100px 0 140px 0;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 100px 0 140px 0;
  }

  background-image: radial-gradient(#feff00c7 1px, transparent 0px),
    linear-gradient(to right, #d664be, #7d1128);
  background-repeat: repeat;
  background-size: 30px 30px, auto;
  background-position: center;
`

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 40px auto 40px;
  text-align: center;
  width: fit-content;
  backdrop-filter: blur(10px);
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: #fff;
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  width: fit-content;
  backdrop-filter: blur(10px);
`

const FeatureText = styled.p`
  text-align: center;
  color: #fff;
  font-size: 22px;
  width: fit-content;
  backdrop-filter: blur(10px);
`
