import {
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Switch,
  Typography,
  makeStyles,
} from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import styled from "styled-components"
import { Container, Section } from "../global"

const useStyles = makeStyles((theme) => ({
  plansContainer: {
    width: "100%",
    padding: theme.spacing(1),
  },
  planCard: {
    padding: "5%",
    height: "100%",
    textAlign: "center",
    backgroundColor: "#f6f9fc",
    minWidth: 320,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  body: {
    overflow: "auto",
    padding: theme.spacing(1, 3, 3, 3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  modalText: {
    fontFamily: theme.typography.titleFontFamily,
    fontSize: 24,
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
  primaryColorText: {
    color: "#7678ed",
  },
}))

const Pricing = () => {
  const classes = useStyles()
  const [monthlyPlans, setMonthlyPlans] = React.useState([])
  const [yearlyPlans, setYearlyPlans] = React.useState([])
  const [shouldShowYearlyPlans, setShouldShouldYearlyPlans] =
    React.useState(false)

  React.useEffect(() => {
    const fetchPaymentPlans = async () => {
      const paymentPlansUrl = `https://cocatus-landing-page-public-store.s3.us-west-2.amazonaws.com/products.json`
      const fetchResult = await fetch(paymentPlansUrl)
      const paymentPlans = await fetchResult.json()

      const monthly = []
      const yearly = []

      paymentPlans.forEach((plan) => {
        if (plan.freq === "month_1") monthly.push(plan)
        else yearly.push(plan)
      })

      setMonthlyPlans(monthly)
      setYearlyPlans(yearly)
    }

    fetchPaymentPlans()
  }, [])

  function handlePriceFreqOnChange() {
    setShouldShouldYearlyPlans((prevBool) => !prevBool)
  }

  function renderPlanCard(plan) {
    return (
      <Grid key={plan.price_id} item sm={12} md={6}>
        <Paper className={classes.planCard} square>
          <Typography
            className={clsx(classes.modalText, classes.bold)}
            variant="h6"
          >
            {plan.name}
          </Typography>
          <Typography
            style={{ color: "#7678ed" }}
            className={clsx(classes.modalText, classes.bold)}
            variant="h6"
          >
            {(plan.price / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Typography>
          {plan.services && (
            <List dense style={{ height: 220, fontSize: 18 }}>
              {plan.services.map((feature) => (
                <ListItem key={feature.feature_id}>
                  - {feature.feature_desc}
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      </Grid>
    )
  }

  return (
    <StyledSection id="pricing">
      <SectionTitle>Pricing</SectionTitle>
      <PriceContainer>
        <div style={{ width: "100%" }}>
          <Grid
            component="label"
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item>Monthly</Grid>
            <Grid item>
              <Switch
                checked={shouldShowYearlyPlans}
                onChange={handlePriceFreqOnChange}
                color="default"
              />
            </Grid>
            <Grid item>
              Yearly{" "}
              <span className={clsx(classes.primaryColorText, classes.bold)}>
                (15% off)
              </span>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {shouldShowYearlyPlans
              ? yearlyPlans.map((plan) => renderPlanCard(plan))
              : monthlyPlans.map((plan) => renderPlanCard(plan))}
          </div>
        </div>
      </PriceContainer>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Message>All plans include a free 14-day trial</Message>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled(Section)`
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
  background-color: #f6f9fc;
  padding: 120px 0 80px 0;
`

const PriceContainer = styled(Container)`
  padding: 40px 0 40px 0;
  display: flex;
  justify-content: center;
`

const SectionTitle = styled.h3`
  display: flex;
  justify-content: center;
  margin: 0 auto 20px;
  text-align: center;
`

const Message = styled.h4`
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
  font-style: italic;
`
export default Pricing
