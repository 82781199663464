import React from "react"
import { Section, Container } from "../global"
import FaqComponent from "react-faq-component"
import styled from "styled-components"

const Faq = () => {
  const [cocatusEmail, setCocatusEmail] = React.useState(" ")

  const data = {
    rows: [
      {
        title: "What is Cocatus?",
        content:
          "Cocatus is a software as a service provider that offers computer assisted coding services for ICD-10-CM and ICD-10-PCS. Our innovative and proprietary algorithms allow for accurate generation of ICD codes from clinical texts. Additionally, our search tools allow coders to seamlessly refine the generated codes and choose the correct codes.",
      },
      {
        title: "How well does Cocatus AI work?",
        content:
          "Cocatus AI generates code suggestions from the entire ICD-10-CM and ICD-10-PCS code base, including codes used in inpatient and outpatient settings. In our in-house testing using hundreds of inpatient documents, on average Cocatus AI was able to suggest 82% of the correct codes. Cocatus AI assists coders by directing attention to key parts of the document where codes are automatically identified, reducing the amount of typing and searching needed.",
      },
      {
        title: "Which medical terminologies do Cocatus support?",
        content:
          "Currently, we support the coding of ICD-10-CM and ICD-10-PCS. We plan to support more medical terminologies in the future.",
      },
      {
        title: "Does Cocatus support manual lookup of codes?",
        content:
          "Currently, we support index and tabular search of ICD-10-CM and ICD-10-PCS codes with user friendly display of search results. We also support the search of ICD-10-PCS definitions.",
      },
      // {
      //   title: "Does Cocatus offer a free trial period to try out the product?",
      //   content: "We offer X days free trial of the product.",
      // },
      // {
      //   title: "What is the refund policy?",
      //   content:
      //     "Cocatus is flexible, you can easily cancel your subscription any time. There is no cancellation fee.",
      // },
      {
        title: "What web browsers does Cocatus support?",
        content:
          "We support latest Google Chrome, Mozilla Firefox, Microsoft Edge, and Safari.",
      },
      {
        title: "Is there any guidance on how to use the software?",
        content:
          'Our YouTube channel contains a tutorial playlist which can be accessed using the this <a  target="_blank" rel="noreferrer" href="https://youtube.com/playlist?list=PL6YaHma-HLhyFnovf1R-jYxYZn05RcSri">link</a>',
      },
      {
        title: "How to contact us?",
        content: `After logging in, click on the 'QUESTIONS' button from top of the page to send us a message. One of us will get back to you.`,
      },
    ],
  }

  React.useEffect(() => {
    const obfuscateEmailIntervalId = setInterval(() => {
      //! This depends on the order of the items in the data array below!!
      const indexOfFaqEntryContainingEmail =
        data.rows.findIndex((faqEntry) =>
          faqEntry.content.toLowerCase().includes("email")
        ) + 1

      const faqEntryContainingEmail = document.querySelectorAll(
        `[aria-controls="react-faq-rowcontent-${indexOfFaqEntryContainingEmail}"]`
      )?.[0]

      if (faqEntryContainingEmail) {
        faqEntryContainingEmail.addEventListener("click", () => {
          // click handler is run before the aria-expanded value is updated.
          // i.e., the value is false if the faq is open, true if not
          if (
            faqEntryContainingEmail.getAttribute("aria-expanded") === "false"
          ) {
            setCocatusEmail("info[at]cocatus.com")
          } else {
            setCocatusEmail(" ")
          }
        })

        clearInterval(obfuscateEmailIntervalId)
      }
    }, 100)
  }, [])

  return (
    <StyledSection id="faq">
      <Container>
        <SectionTitle>Frequently Asked Questions</SectionTitle>
        <FaqComponent
          data={data}
          styles={{
            rowContentPaddingTop: "8px",
            rowContentPaddingBottom: "24px",
            rowContentPaddingLeft: "24px",
            rowContentPaddingRight: "24px",
            rowContentColor: "#fff",
            rowTitleColor: "#fff",
            arrowColor: "#fff",
          }}
        />
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled(Section)`
  background-color: #0a2540;
`

const FaqContainer = styled(Container)`
  padding: 80px 0;
`

const SectionTitle = styled.h3`
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

export default Faq
